import React, {useEffect, useState} from 'react';
import styles from "./Form.module.css";
import openIcon from "./open.png";
import progressIcon from "./progress.png";
import doneIcon from "./done.png";

function Form (props) {

    if(props.value1 == 0 && props.value2 == 0) {

        return (
            <div style={{position: 'relative'}}>
                <div className={styles.countContainer} status="OPEN">{props.value1}/{props.value2}</div>
                <img className={styles.image} src={openIcon}/>
            </div>
        )
    } else if(props.value1 != props.value2) {

        return (
            <div style={{position: 'relative'}}>
                <div className={styles.countContainer} status="PROGRESS">{props.value1}/{props.value2}</div>
                <img className={styles.image} src={progressIcon}/>
            </div>
        )
    } else {

        return (
            <div style={{position: 'relative'}}>
                <div className={styles.countContainer} status="DONE">{props.value1}/{props.value2}</div>
                <img className={styles.image} src={doneIcon}/>
            </div>
        )
    }
}

export default Form;