import './App.css';

import React, {useState} from 'react';
import {Route, HashRouter as Router, Routes} from "react-router-dom";
import LoginForm from './login/Form';
import ReviewList from './review/List';
import ReviewForm from './review/Form';
import {ConfirmDialog} from "primereact/confirmdialog";

function App() {
    const [maintenance, setMaintenance] = useState(false);
    if (window.location.port !== "3000" && window.location.protocol !== "https:") {
        window.location.protocol = "https:";
    }

    return (
        <div className="App">
            {maintenance && (
                <div style={{fontSize: 24, textAlign: 'center'}}>
                    <h1>Wartungsarbeiten</h1>
                    <div>Unsere Seite wird aktualisiert</div>
                    <h4 style={{color: 'red'}}>Bitte besuchen Sie uns später!</h4>
                </div>
            )}
            {!maintenance && (
                <React.Fragment>
                    <ConfirmDialog/>
                    <Router>
                        <Routes>
                            <Route exact path="/" element={<LoginForm/>}/>
                            <Route exact path="/login" element={<LoginForm/>}/>
                            <Route exact path="/reviews" element={<ReviewList/>}/>
                            <Route exact path="/reviews/:id" element={<ReviewForm/>}/>
                        </Routes>
                    </Router>
                </React.Fragment>
            )}
        </div>
    );
}

export default App;
