import EvenEmitter from "./EvenEmitter";
import axios from "axios";

const LOCALHOST = 'localhost:8080'
const VENNOXHOST = 'review.venmedia.de'
const BROWSERHOST = window.location.host
const CONTEXT = window.location.port==="3000" ? window.location.protocol+"//"+LOCALHOST : window.location.protocol+"//"+VENNOXHOST;


export const NOTIFICATION_PRV           = '/api/prv/notifications'

export const _GET = (url, filter) => axios.get(CONTEXT + url + convertFilter(filter), createConfig(url)).then(processSuccess).catch(processError)
export const _POST = (url, body) => axios.post(CONTEXT + url, body, createConfig(url)).then(processSuccess).catch(processError)
export const _PUT = (url, body) => axios.put(CONTEXT + url, body, createConfig(url)).then(processSuccess).catch(processError)
export const _DELETE = (url) => axios.delete(CONTEXT + url, createConfig(url)).then(processSuccess).catch(processError)

export const _DOWNLOAD = (url, filename, filter) => {
    fetch(CONTEXT + url+convertFilter(filter), {
        headers: {
            'Authorization':  'Bearer ' + localStorage.getItem('jwtToken')}
    })
        .then(response => {
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            });
            //window.location.href = response.url;
        });
}

export const _INIT_EVENT = (auth, url) => {
    let config = {
        headers: {
            'Content-Type':'application/json'
        },
        timeout: 3600000
    }
    if(auth) {
        config = {
            headers: {
                'Content-Type':'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwtToken')}` },
            timeout: 3600000
        };
    }
    return axios.get(CONTEXT + NOTIFICATION_PRV, config)
}

export const _INIT_NOTIFICATION = () => {
    _INIT_EVENT(true, CONTEXT + NOTIFICATION_PRV)
        .then(response => {
            EvenEmitter.dispatch("NOTIFICATION", response.data)
            _INIT_NOTIFICATION()
        })
        .catch( error => {
            setTimeout(null, 10000);
            _INIT_NOTIFICATION()
        })
}

export const _VALID_EMPTY = (value) => {
    if(value == null || value == '' || value == ' ') { return true } else {return false}
}



const createConfig = (url, timeout) => {
    //axios.defaults.crossDomain = true;
    let config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
            //'Access-Control-Allow-Origin': '*',
        },
        timeout: timeout
    }
    return config
}

const convertFilter = (filter) => {
    let queryFilter = ''
    if(filter != null) {
        let filterCopy = JSON.parse(JSON.stringify(filter))
        Object.keys(filterCopy).forEach((key) => (filterCopy[key] == null) && delete filterCopy[key]);
        queryFilter = '?' + new URLSearchParams(filterCopy).toString()
    }
    return queryFilter
}

const processSuccess = (response) => {
    if(response.headers['profile-jwt-token'] != null) {
        localStorage.setItem("jwtToken", response.headers['profile-jwt-token']);
        localStorage.setItem("jwtTokenMinutes", "59");
        localStorage.setItem("jwtTokenSeconds", "59");
    }

    EvenEmitter.dispatch("NOTIFICATION_HTTP_SUCCESS_RESPONSE", response.data)
    return new Promise(function(resolve, reject) {
        resolve(Promise.resolve(response));
    })
}

const processError = (error) => {
    if(error.response.status == 401) {
        alert("HEllo")
        localStorage.clear()
        window.location.href = "/";
    } else {
        EvenEmitter.dispatch("NOTIFICATION_HTTP_ERROR_RESPONSE", error.response.data)
    }
    return Promise.reject(error)
}


