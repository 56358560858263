import React, {useState} from 'react'
import styles from './Form.module.css';
import {AppApi} from "../AppApi";
import {InputText} from "primereact/inputtext";
import {Password} from 'primereact/password';
import {Button} from "primereact/button";

function Form(props) {
    const [credentials, setCredentials] = useState({
        username: null,
        password: null,
    });

    const events = {
        login: () => {
            AppApi.pub.profile.post(credentials)
                .then(response => {
                    window.location.href = "/#/reviews"
                    localStorage.setItem("jwtToken", response.data.jwtToken);
                    localStorage.setItem("jwtTokenMinutes", "59");
                    localStorage.setItem("jwtTokenSeconds", "59");
                    localStorage.setItem("role", response.data.role);
                    localStorage.setItem("username", response.data.username);
                })
                .catch(console.log);
        }
    }

    return (
        <div>
            <div style={{width: '100%'}} className={styles.root}>
                <div className={styles.panel}>
                    <div style={{textAlign: 'center', paddingBottom: 10}}>
                        VENPAGE - Conten Management System
                    </div>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                        </span>
                        <InputText
                            style={{width: '100%'}}
                            value={credentials.username}
                            placeholder="Benutzername"
                            onChange={(event) => setCredentials({...credentials, username: event.target.value})}
                        />
                    </div>
                    <div className="p-inputgroup" style={{paddingTop: '5px'}}>
                        <span className="p-inputgroup-addon">
                            <i className="pi pi-lock"></i>
                        </span>
                        <Password
                            style={{width: '100%'}}
                            value={credentials.password}
                            placeholder="Passwort"
                            onChange={(event) => setCredentials({...credentials, password: event.target.value})}
                            onKeyPress={event => event.key === 'Enter' && events.login()}
                        />
                    </div>
                    <div style={{textAlign: "right"}} >
                        <Button
                            label="LOGIN"
                            className="p-button-secondary"
                            style={{marginTop: 4}}
                            icon="pi pi-sign-in"
                            onClick={() => events.login()}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;