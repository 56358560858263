import React, {useEffect, useState} from 'react';
import styles from './List.module.css';
import tableStyles from './../Table.module.css';
import {AppApi} from "../AppApi";
import EEEditable from "../component/EEEditable";
import {FileUpload} from "primereact/fileupload";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import emailImg from "./email.png";
import VersionForm from "./version/Form"
import ProgressForm from "./progress/Form"
import {InputText} from "primereact/inputtext";

function List(props) {

    const [update, force] = useState(true);
    const [data, setData] = useState([]);


    const [filter, setFilter] = useState({
        text: "",
    });


    useEffect(() => events.load(), []);

    const events = {

        filter: () => {
            data?.map(row => {
                let search = row.label+' '+row.name+' '+row.email

                row.display = 'none'

                if (search.toUpperCase().indexOf(filter.text.toUpperCase()) > -1) {
                    row.display = null
                }

            })
            force(!update)
        },
        load: () => {
            AppApi.prv.task.get(filter)
                .then(response => {
                    setData(response.data)
                })
                .catch(console.log)
        },
        postNewReview: () => {
            AppApi.prv.task.post()
                .then(response => {
                    events.load()
                })
                .catch(console.log)
        },
        upload: (event, entity, entityId) => {
            Array.from(event.files).map(file => {
                AppApi.prv.task.content.postUpload(
                    file,
                    entityId).then(response => {
                        events.load()
                    }).then(response => event.options.clear())
                    .catch(console.log)
            })
        },
    }



    return (
        <div className="wrapper">
            <div style={{background: '#FFFFFF'}}>
                <div style={{padding: 10}}>
                    <div style={{display: 'inline-flex', padding: 6, width: '290px'}}>
                        <div className='p-input-icon-left' style={{width: '100%'}}>
                            <i className="pi pi-search"/>
                            <InputText
                                style={{width: '400px'}}
                                autoFocus={true}
                                placeholder="Suchen"
                                value={filter.text}
                                onChange={(e) => {
                                    filter.text = e.target.value
                                    events.filter()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <table style={{width: '100%', borderSpacing: 0}} cellSpacing="0" cellPadding="0">
                <tbody>
                <tr style={{textAlign: 'center', background: '#F8F9FA'}}>
                    <th className={tableStyles.tableHeader} style={{textAlign: 'left'}}>Label</th>
                    <th className={tableStyles.tableHeader} style={{textAlign: 'left'}}>Name</th>
                    <th className={tableStyles.tableHeader} style={{textAlign: 'left', width: '120px'}}>Version</th>
                    <th className={tableStyles.tableHeader} style={{textAlign: 'left', width: '120px'}}>Status</th>
                    <th className={tableStyles.tableHeader} style={{textAlign: 'left'}} colSpan={2}>Email</th>
                    <th colSpan={3}
                        className={tableStyles.tableHeader}
                        style={{paddingRight: 18, textAlign: 'right', borderRight: 'solid 0px var(--venox-background1)'}}>
                        <i
                            className="fas fa-plus"
                            style={{cursor: 'pointer'}}
                            onClick={() => events.postNewReview()}/>
                    </th>
                </tr>
                {data.map(row => row.display == null &&
                    <React.Fragment>
                        <tr className={styles.formRow} onClick={() => window.location.href = "/#/reviews/" + row.uuid}>

                            <td className={tableStyles.tableCell}>
                                <EEEditable
                                    label="Label"
                                    type='TEXT'
                                    entity='REVIEW_TASK'
                                    entityId={row.id}
                                    field='label'
                                    value={row.label}
                                    onChanged={(e) => events.load()}/>
                                {/*<div style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>https://review.venmedia.de/#/reviews/{row.uuid}</div>*/}
                            </td>
                            <td className={tableStyles.tableCell}>
                                <EEEditable
                                    label="Name"
                                    type='TEXT'
                                    entity='REVIEW_TASK'
                                    entityId={row.id}
                                    field='name'
                                    value={row.name}
                                    onChanged={(e) => events.load()}/>
                                {/*<div style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>https://review.venmedia.de/#/reviews/{row.uuid}</div>*/}
                            </td>
                            <td className={tableStyles.tableCell} style={{textAlign: 'left'}}>
                                <VersionForm value1={row.contents.length}/>
                            </td>
                            <td className={tableStyles.tableCell} style={{textAlign: 'left'}}>
                                <ProgressForm value1={row.currentMarkerDoneSize} value2={row.currentMarkerSize}/>
                            </td>
                            <td className={tableStyles.tableCell} style={{textAlign: 'left'}}>
                                <img src={emailImg} width={30}/>
                            </td>
                            <td className={tableStyles.tableCell}>
                                <EEEditable
                                    label="Email"
                                    type='TEXT'
                                    entity='REVIEW_TASK'
                                    entityId={row.id}
                                    field='email'
                                    value={row.email}
                                    onChanged={(e) => events.load()}/>
                            </td>
                            <td className={tableStyles.tableCell} style={{textAlign: 'center', width: 50}}>
                                <Button
                                    icon="pi pi-envelope"
                                    onClick={(e) => {
                                        e.stopPropagation();

                                        confirmDialog({
                                            message: <div style={{padding: 10}}>Möchtest du den eine Mail senden?</div>,
                                            header: 'Bestätigung',
                                            acceptLabel: 'ja',
                                            accept: () => {
                                                AppApi.prv.email.postNotification(row.id).then(response => {
                                                    alert("Email gesendet!")
                                                }).catch(console.log)
                                            },
                                            rejectLabel: 'nein',
                                            reject: () => null
                                        });

                                    }}
                                />
                            </td>
                            <td className={tableStyles.tableCell} style={{textAlign: 'center', width: 50}}>
                                <Button
                                    icon="pi pi-trash"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        confirmDialog({
                                            message: <div style={{padding: 10}}>Möchtest du den Review löschen?</div>,
                                            header: 'Bestätigung',
                                            acceptLabel: 'ja',
                                            accept: () => {
                                                AppApi.prv.task.delete(row.id)
                                                    .then(response => {
                                                        events.load()
                                                    })
                                                    .catch(console.log)
                                            },
                                            rejectLabel: 'nein',
                                            reject: () => null
                                        });
                                    }}
                                />
                            </td>
                            <td className={tableStyles.tableCell} style={{textAlign: 'center', width: 50}}>
                                <FileUpload
                                    chooseOptions={{icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, style: {padding: 8, width: 36, height: 36}}}
                                    auto
                                    mode="basic"
                                    contentStyle={{minHeight: 400}}
                                    customUpload={true}
                                    onClick={(e) => e.stopPropagation()}
                                    uploadHandler={(e) => events.upload(e, 'REVIEW', row.id)}
                                    multiple maxFileSize={200000000}
                                    emptyTemplate={<p className="p-m-0">Drag and drop files to here to upload.</p>} />
                            </td>
                        </tr>
                    </React.Fragment>
                )}
                </tbody>
            </table>
        </div>
    )
}

export default List;