import React, {useEffect, useState} from 'react';
import styles from './Form.module.css';
import {AppApi} from "../AppApi";
import {useParams} from "react-router-dom";
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import {Slider} from "primereact/slider";
import logoDark from "./logo_dark.png";
import {confirmDialog} from "primereact/confirmdialog";
import tableStyles from "../Table.module.css";
import CreateFormDialog from "./marker/CreateFormDialog";
import visualImg from "./marker/visual.png";
import voiceImg from "./marker/voice.png";

function Form(props) {

    const { id } = useParams();
    let idCache = null;

    const [videoSrc, setVideoSrc] = useState();
    const [data, setData] = useState();
    const [content, setContent] = useState();
    const [version, setVersion] = useState();
    const [versions, setVersions] = useState([]);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [paused, setPaused] = useState(true);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const [selectedMarkerId, setSelectedMarkerId] = useState();
    const [markerAvailable, setMarkerAvailable] = useState(false);
    const [markerX, setMarkerX] = useState();
    const [markerY, setMarkerY] = useState();
    const [markerXShadow, setMarkerXShadow] = useState();
    const [markerYShadow, setMarkerYShadow] = useState();
    const [videoOffsetWidth, setVideoOffsetWidth] = useState();
    const [videoOffsetHeight, setVideoOffsetHeight] = useState();
    const [attachmentShowMore, setAttachmentShowMore] = useState(false);

    const video = React.createRef()
    const versionRef = React.createRef()
    const playbackRateMenu = React.createRef()



    useEffect(() => {
        if(id && !idCache) {
            idCache = id
            events.load()
        }
    }, [id]);
    useEffect(() => {
        setMarkerX(null)
        setMarkerY(null)
        setMarkerXShadow(null)
        setMarkerYShadow(null)
        if(markerAvailable == false && content != null) {
            events.loadContentMeta()
        }
    }, [markerAvailable]);

    const events = {
        load: (loadContent) => {
            AppApi.pub.task.getOneUuid(id)
                .then(response => {
                    setData(response.data)
                    let versions = []
                    response.data.contents.map( (content, index) => {
                        versions.push(
                            {label: 'Version ' + (index+1),
                                command: () => {
                                    setVersion(index+1)
                                    events.loadContent(content)
                                }})
                    })
                    setVersions(versions)
                    if(loadContent || content == null) {
                        setVersion(response.data.contents.length)
                        events.loadContent(response.data.contents[response.data.contents.length-1])
                    } else {
                        response.data.contents.map( (item, index) => {
                            if(item.id == content.id) {
                                setVersion(index+1)
                                events.loadContent(item)
                            }
                        })
                    }
                })
                .catch(console.log)
        },
        loadContent: (content) => {
            content.markers.map( marker => {
                marker.ref = React.createRef()
            })

            setContent(content)

            const LOCALHOST = 'localhost:8080'
            const VENNOXHOST = 'review.venmedia.de'
            const CONTEXT = window.location.port==="3000" ? window.location.protocol+"//"+LOCALHOST : window.location.protocol+"//"+VENNOXHOST;

            fetch(CONTEXT + '/api/pub/module/review/task/contents/download/' + content.id)
                .then(response => {
                    response.blob().then(blob => {
                        setVideoSrc(URL.createObjectURL(blob))
                    });
                });
        },
        loadContentMeta: () => {
            AppApi.pub.task.contents.getOne(content.id)
                .then(response => {
                    response.data.markers.map( marker => {
                        marker.ref = React.createRef()
                    })
                    setContent(response.data)
                })
                .catch(console.log)
        },
        deleteMarker: (id) => {
            confirmDialog({
                message: <div style={{padding: 10}}>Möchten Sie die Markierung löschen?</div>,
                header: 'Bestätigung',
                acceptLabel: 'ja',
                accept: () => {
                    AppApi.pub.task.contents.marker.delete(selectedMarkerId)
                        .then(response => {
                            events.loadContentMeta()
                        })
                        .catch(console.log)
                },
                rejectLabel: 'nein',
                reject: () => null
            });
        },
        onMouseMove: (e) => {
            if(markerY == null) {
                setVideoOffsetWidth(video.current.offsetWidth)
                setVideoOffsetHeight(video.current.offsetHeight)
                setMarkerXShadow(((e.clientX-1)/video.current.offsetWidth))
                setMarkerYShadow(((e.clientY-1)/video.current.offsetHeight))
            }
        },
        onMouseDown: (e) => {
            if(markerAvailable) {
                setMarkerX(((e.clientX-1)/video.current.offsetWidth))
                setMarkerY(((e.clientY-1)/video.current.offsetHeight))
            }
        },
        onPlayRateChange: (playRate) => {
            setPlaybackRate(playRate)
            video.current.playbackRate = playRate
            video.current.play()
        },

    }

    function secToHumanTime(s) {

        if(s != null) {
            var m = Math.floor(s / 60);
            m = (m >= 10) ? m : "0" + m;
            s = Math.floor(s % 60);
            s = (s >= 10) ? s : "0" + s;
            return m + ":" + s;
        } else {
            return '00:00:00'
        }
    }

    content?.markers.map(marker => {
        if(marker.ref != null && marker.ref.current != null) {
            marker.ref.current.currentTime = marker.playbackRate
        }
    })


    return (
        <div>
            <CreateFormDialog
                visible={markerAvailable && markerX != null && markerY != null}
                contentId={content?.id}
                currentTime={currentTime}
                markerX={markerX}
                markerY={markerY}
                onClose={() => setMarkerAvailable(false)}
            />
            <table cellPadding={0} cellSpacing={0}>
                <thead>
                    <tr style={{textAlign: 'left', background: '#FFFFFF', color: '#292929'}}>
                        <th  style={{verticalAlign: 'bottom'}}>
                            <img src={logoDark} style={{width: 200, cursor: 'pointer'}}/>
                        </th>
                        <th style={{verticalAlign: 'bottom',  width: '100%'}}>
                            <span style={{fontSize: 18, paddingLeft: 2, paddingTop: 20, float: 'left'}}>{data?.name}</span>
                            <span style={{paddingLeft: 10, float: 'right'}}>
                                <Menu
                                    model={versions}
                                    popup
                                    ref={versionRef}
                                    id="popup_menu" />

                                <Button
                                    label={"Version " +version}
                                    className="p-button-rounded p-button-outlined p-button-success"
                                    icon="pi pi-chevron-down"
                                    iconPos="right"
                                    style={{background: '#FFFFFF', margin: '5px'}}
                                    onClick={(event) => versionRef.current.toggle(event)}/>

                                {localStorage.getItem('jwtToken') && (
                                    <Button
                                        className="p-button-rounded p-button-outlined p-button-danger"
                                        style={{background: '#FFFFFF', margin: '5px 7px 5px 5px'}}
                                        icon="pi pi-trash"
                                        onClick={() => {
                                            confirmDialog({
                                                message: <div style={{padding: 10}}>Möchtest du die Version wirklich löschen?</div>,
                                                header: 'Bestätigung',
                                                acceptLabel: 'ja',
                                                accept: () => {
                                                    AppApi.prv.task.content.delete(content.id)
                                                        .then(response => {
                                                            events.load(true)
                                                        })
                                                        .catch(console.log)
                                                },
                                                rejectLabel: 'nein',
                                                reject: () => null
                                            });
                                        }}/>
                                )}


                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>

                <tr style={{verticalAlign: 'top', textAlign: 'left', color: '#00503a'}}>
                    <td>


                        {markerAvailable && (<div
                                onMouseMove={(e) => events.onMouseMove(e)}
                                onMouseDown={(e) => events.onMouseDown(e)}
                                style={{position: 'absolute', left: markerXShadow * videoOffsetWidth, top: markerYShadow * videoOffsetHeight, width: 30, height: 30, marginLeft: -15, marginTop: -15, border: '2px solid #FF0000', borderRadius: 90}}></div>
                        )}


                        {markerX && markerY && (
                            <div
                                onMouseMove={(e) => events.onMouseMove(e)}
                                onMouseDown={(e) => events.onMouseDown(e)}
                                style={{position: 'absolute', left: markerX * videoOffsetWidth, top: markerY * videoOffsetHeight, width: 30, height: 30, marginLeft: -15, marginTop: -15, background: 'rgba(255,0,0,0.3)', borderRadius: 90}}></div>
                        )}
                        {markerX && markerY && (
                            <div
                                onMouseMove={(e) => events.onMouseMove(e)}
                                onMouseDown={(e) => events.onMouseDown(e)}
                                style={{position: 'absolute', left: markerX * videoOffsetWidth, top: markerY * videoOffsetHeight, width: 30, height: 30, marginLeft: -15, marginTop: -15, border: '2px solid #FF0000', borderRadius: 90}}></div>
                        )}

                        <video
                            style={{marginBottom: -3, cursor: markerAvailable ? 'crosshair' : null}}
                            onMouseMove={(e) => events.onMouseMove(e)}
                            onMouseDown={(e) => events.onMouseDown(e)}
                            onLoadedMetadata={() => {
                                setDuration(video.current.duration.toFixed(2))
                            }}
                            onTimeUpdate={() => {
                                setCurrentTime(video.current.currentTime.toFixed(2))
                            }}
                            onPause={() => {
                                setPaused(true)
                            }}
                            onPlay={() => {
                                setPaused(false)
                            }}
                            loop
                            ref={video}
                            height="640"
                            id="video"
                            src={videoSrc}>
                        </video>
                    </td>
                    <td>
                        <div style={{width: '100%', height: 640, overflow: 'auto', background: '#FFFFFF'}}>

                            {!(markerAvailable && markerX != null && markerY != null) && (
                                <table cellPadding={5} cellSpacing={0} style={{width: '100%'}}>
                                    <tbody>

                                    <tr style={{background: '#292929', color: '#FFFFFF'}}>
                                        <th colSpan={5}>
                                            <span style={{fontSize: 15}}>Änderungen</span>
                                        </th>
                                    </tr>
                                    {content?.markers.map( marker => (
                                        <tr
                                            onClick={() => {
                                                setSelectedMarkerId(marker.id)
                                                video.current.currentTime = marker.playbackRate
                                                setVideoOffsetWidth(video.current.offsetWidth)
                                                setVideoOffsetHeight(video.current.offsetHeight)
                                                setMarkerAvailable(false)
                                                marker.markerX ? setMarkerX(marker.markerX) : setMarkerX(null)
                                                marker.markerY ? setMarkerY(marker.markerY) : setMarkerY(null)
                                            }}
                                            className={selectedMarkerId == marker.id ? styles.markerRowSelected : styles.markerRow}>
                                            <td className={tableStyles.status} status={marker.status} style={{borderBottom: 'solid 1px var(--venox-background1)', borderTop: 'solid 1px var(--venox-background1)'}}/>
                                            <td className={tableStyles.tableCell} style={{background: '#7c7c7c', color: '#FFFFFF'}}>
                                                <video
                                                    style={{marginBottom: -4}}
                                                    width="66"
                                                    ref={marker.ref}
                                                    src={videoSrc}/>
                                                <span style={{fontSize: 14}}>{secToHumanTime(marker.playbackRate)}</span>
                                            </td>
                                            <td className={tableStyles.tableCell}>
                                                { marker.type == "VISUAL" ? (
                                                        <img src={visualImg} width={30}/>
                                                    ) : (
                                                        <img src={voiceImg} width={30}/>
                                                    )
                                                }
                                            </td>
                                            <td className={tableStyles.tableCell} style={{verticalAlign: 'top', width: '100%', fontSize: 14}}>
                                                {localStorage.getItem('jwtToken') && (
                                                    <span>
                                                    <span style={{fontStyle: 'italic', fontWeight: 'bold', cursor: 'pointer'}}>
                                                        {marker.status == 'IN_PROGRESS' && (
                                                            <span
                                                                onClick={() => {
                                                                    AppApi.prv.task.content.marker.put(marker.id, 'status', 'DONE')
                                                                        .then(response => events.loadContentMeta())
                                                                        .catch(console.log)
                                                                }}
                                                                style={{color: 'orange'}}>
                                                            in Bearbeitung
                                                        </span>)}
                                                        {marker.status == 'DONE' && (
                                                            <span
                                                                onClick={() => {
                                                                    AppApi.prv.task.content.marker.put(marker.id, 'status', 'IN_PROGRESS')
                                                                        .then(response => events.loadContentMeta())
                                                                        .catch(console.log)
                                                                }}
                                                                style={{color: 'darkgreen'}}>
                                                            erledigt
                                                        </span>)}
                                                    </span>

                                                </span>
                                                )}
                                                {!localStorage.getItem('jwtToken') && (
                                                    <span style={{fontStyle: 'italic', fontWeight: 'bold'}}>
                                                    {marker.status == 'IN_PROGRESS' && (<span style={{color: 'orange'}}>in Bearbeitung</span>)}
                                                        {marker.status == 'DONE' && (<span style={{color: 'darkgreen'}}>erledigt</span>)}
                                                </span>
                                                )}
                                                <span style={{float: 'right'}}>
                                                <span style={{paddingRight: 5}}>
                                                    <span >{marker.dateCreated?.slice(8, 10)}</span>.
                                                    <span >{marker.dateCreated?.slice(5, 7)}</span>.
                                                    <span >{marker.dateCreated?.slice(0, 4)}</span>
                                                </span>
                                                <span style={{paddingRight: 5}}>{marker.dateCreated?.slice(11, 16)}</span>
                                            </span>


                                                <div>
                                                    {marker.description}
                                                </div>
                                                <div>
                                                    {marker.attachments.length >= 1 && (
                                                        <table>
                                                            <tr>
                                                                <td style={{verticalAlign: 'top'}}><i className="fas fa-paperclip"/></td>
                                                                <td>
                                                                    {!attachmentShowMore && (<span className={styles.attachment} onClick={() => {setAttachmentShowMore(true)}}>[mehr anzeigen ...]</span>)}

                                                                    {attachmentShowMore && marker.attachments.map(attachment =>
                                                                        <div>
                                                                            <span
                                                                                className={styles.attachment}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    AppApi.pub.attachment.download(attachment.id, attachment.filename)
                                                                                }}>{attachment.filename}</span>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    )}


                                                </div>
                                            </td>
                                            <td className={tableStyles.tableCell}>
                                                {marker.id == selectedMarkerId &&
                                                    <div
                                                        className="button"
                                                        onClick={() => events.deleteMarker()}>
                                                        <i style={{paddingLeft: 3, color: '#000000'}} className="pi pi-trash"/>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}


                        </div>
                    </td>
                </tr>
                <tr style={{verticalAlign: 'top', textAlign: 'left'}}>

                    <td style={{background: '#292929', color: '#01d997'}}>

                        <div style={{paddingLeft: 10, paddingRight: 10, paddingTop: 10}}>
                            <Slider
                                value={currentTime*100}
                                onChange={(e) => {
                                    setCurrentTime(e.value/100)

                                    if(video.current != null) {
                                        video.current.currentTime = e.value/100
                                    }

                                    setSelectedMarkerId(null)
                                    setMarkerAvailable(false)
                                    setMarkerX(null)
                                    setMarkerY(null)
                                    setMarkerXShadow(null)
                                    setMarkerYShadow(null)

                                }}
                                max={duration*100}/>
                            <div>{secToHumanTime(currentTime)}/{secToHumanTime(duration)}

                                <span style={{paddingLeft: 10}}>
                                        <Menu
                                            model={
                                                [
                                                    { label: 'Geschwindigkeit 0.25', command: () => events.onPlayRateChange(0.25)},
                                                    { label: 'Geschwindigkeit 0.50', command: () => events.onPlayRateChange(0.50)},
                                                    { label: 'Geschwindigkeit 0.75', command: () => events.onPlayRateChange(0.75)},
                                                    { label: 'Geschwindigkeit 1.00', command: () => events.onPlayRateChange(1.00)},
                                                    { label: 'Geschwindigkeit 1.25', command: () => events.onPlayRateChange(1.25) },
                                                    { label: 'Geschwindigkeit 1.50', command: () => events.onPlayRateChange(1.5) },
                                                    { label: 'Geschwindigkeit 1.75', command: () => events.onPlayRateChange(1.75) },
                                                    { label: 'Geschwindigkeit 2.00', command: () => events.onPlayRateChange(2.00) }
                                                ]
                                            }
                                            popup
                                            ref={playbackRateMenu}
                                            id="popup_menu" />
                                        <span
                                            style={{float: 'right'}}
                                            onClick={(event) => playbackRateMenu.current.toggle(event)}>Geschwindigkeit {playbackRate.toFixed(2)}</span>
                                    </span>
                            </div>
                        </div>
                        <div style={{padding: 10}}>




                            <div>

                                {paused && <span>

                                    <div
                                        className="button"
                                        onClick={() => {
                                            video.current.currentTime = video.current.currentTime - 0.05
                                        }}>
                                        <i style={{paddingLeft: 1}} className="pi pi-angle-double-left"/>
                                    </div>

                                    <div
                                        className="button"
                                        style={{height: 60, width: 60}}
                                        onClick={() => {
                                            setMarkerAvailable(false)
                                            setSelectedMarkerId(null)
                                            setMarkerX(null)
                                            setMarkerY(null)
                                            setMarkerXShadow(null)
                                            setMarkerYShadow(null)
                                            video.current.play()
                                        }}>
                                        <i style={{paddingLeft: 13, paddingTop: 12}} className="pi pi-play"/>
                                    </div>


                                    <div
                                        className="button"
                                        onClick={() => {
                                            video.current.currentTime = video.current.currentTime + 0.05
                                        }}>
                                        <i style={{paddingLeft: 3}} className="pi pi-angle-double-right"/>
                                    </div>
                                    </span>}
                                {!paused && <span>
                                    <div
                                        className="button"
                                        style={{marginLeft: 44, height: 60, width: 60}}
                                        onClick={() => {
                                            video.current.currentTime = video.current.currentTime.toFixed(1)
                                            video.current.pause()
                                        }}>
                                        <i style={{paddingLeft: 13, paddingTop: 12}} className="pi pi-pause"/>
                                    </div>
                                </span>}

                                {paused && !markerAvailable && <span
                                    style={{float: 'right'}}>
                                        <div
                                            className="button"
                                            style={{width: 205, marginTop: 10, height: 44}}
                                            onClick={() => {
                                                setMarkerAvailable(true)
                                                setSelectedMarkerId(null)
                                                setMarkerX(null)
                                                setMarkerY(null)
                                                setMarkerXShadow(null)
                                                setMarkerYShadow(null)
                                            }}>
                                            <span style={{paddingLeft: 20}}>Markierung setzen</span>
                                        </div>
                                    </span>}

                                {paused && markerAvailable && <span
                                    style={{float: 'right'}}>
                                        <div
                                            className="button"
                                            style={{width: 205, marginTop: 10, color: 'red'}}
                                            onClick={() => setMarkerAvailable(false)}>
                                            <span>Markierung abbrechen</span>
                                        </div>
                                    </span>}



                            </div>

                            {paused && markerAvailable && <span
                                style={{float: 'right', color: 'red', fontWeight: 600}}>
                                        <div>Bitte markieren Sie den Bereich im Video den Sie ändern möchten!</div>
                                    </span>}
                        </div>

                    </td>
                    <td style={{background: '#292929', color: '#01d997'}}>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Form;