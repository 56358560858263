import React, {useEffect, useState} from 'react';
import styles from "./Form.module.css";
import icon from "./kamera.png";

function Form (props) {

    return (
        <div style={{position: 'relative'}}>
            <div className={styles.countContainer}>{props.value1}</div>
            <img className={styles.image} src={icon}/>
        </div>
    )
}

export default Form;