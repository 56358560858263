import React, {Component} from 'react';
import styles from './EEEditable.module.css';
import {AppApi} from "../AppApi";
import {OverlayPanel} from "primereact/overlaypanel";
import {ListBox} from "primereact/listbox";

class EEEditable extends Component {

    constructor(props) {
        super(props);
        this.refValueComponent = React.createRef()
        this.textOverlayPanel = React.createRef()
        this.refTextArea = React.createRef()
        this.state = {
            dialogVisible: false,
            value: null,
            edit: false
        }

        this.action = {
            hide: () => {
                this.setState({dialogVisible: false, edit: false})
                if(this.props.onHide != null) {
                    this.props.onHide()
                }
                if(this.textOverlayPanel != null) {
                    if(this.textOverlayPanel.toggle != null) {
                        this.textOverlayPanel.toggle(null)
                    }
                }
            },
            postChange: () => {
                if(this.props.entity == 'REVIEW_TASK'){
                    AppApi.prv.task.put(this.props.entityId, this.props.field, this.state.value)
                        .then(response => {
                            this.state.edit = false;
                            this.state.mouseEnter = false;
                            this.props.onChanged()
                            this.action.hide()
                        })
                        .catch(console.log)
                }
            },
        }
    }

    componentDidMount() {
        this.state.value = this.props.value

        this.state.options = []
        this.props.options?.map(option => {
            if(option.notSelectable != true) {
                this.state.options.push(option)
            }

        })
        this.forceUpdate()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.value != prevProps.value ) {
            this.componentDidMount()
        }
        if(this.props.options != prevProps.options ) {
            this.componentDidMount()
        }
        if(this.props.dialogVisible != prevProps.dialogVisible ) {
            this.componentDidMount()
        }

        if(this.refTextArea) {
            if(this.refTextArea.style) {
                this.refTextArea.style.height = 'inherit';
                this.refTextArea.style.height = `${this.refTextArea.scrollHeight}px`;
            }
        }
    }


    render() {
        if(this.props.type == 'TEXT') {
            return (
                <React.Fragment>
                    {this.props.showLabel == true && (
                        <div>
                            <div style={{paddingLeft: '2px', fontSize: '10px'}}>{this.props.label}</div>
                            <div style={{paddingLeft: '2px', fontSize: '10px'}}>{this.props.label}</div>
                        </div>
                    )}

                    <div
                         className={this.state.edit ? styles.inputTextEditContainer : styles.inputTextContainer}
                         onMouseEnter={() => this.setState({mouseEnter: true})}
                         onMouseLeave={() => this.setState({mouseEnter: false})}>
                        {this.state.edit == false && (
                            <table cellSpacing="0" cellPadding="0">
                                <tbody>
                                    <tr>
                                        <td>
                                            {this.props.link && (
                                                <div
                                                    style={{textDecoration: 'underline', cursor: 'pointer', color: 'blue'}}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(this.props.link, "_blank")
                                                    }}>
                                                    {this.props.shortValue ? this.props.shortValue : this.props.value ? this.props.value : '...'}
                                                </div>
                                            )}
                                            {!this.props.link && (
                                                <div>
                                                    {this.props.shortValue ? this.props.shortValue : this.props.value ? this.props.value : '...'}
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            {this.state.mouseEnter && this.state.edit == false && (
                                                <i style={{paddingLeft: 5, cursor: 'pointer'}} className="fas fa-pencil-alt"
                                                   onClick={(e) => {
                                                       e.stopPropagation();
                                                       this.setState({edit: true})
                                                   }}/>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}

                        {this.state.edit == true && (
                            <table style={{width: '100%'}} cellSpacing="0" cellPadding="0">
                                <tbody>
                                    <tr>
                                        <td style={{width: '100%'}}>
                                            <textarea
                                                ref={(el) => this.refTextArea = el}
                                                rows={1}
                                                style={{
                                                    width: '100%',
                                                    textAlign: this.props.textAlign
                                                }}
                                                className={styles.inputText}
                                                placeholder="..."
                                                type="text"
                                                required
                                                value={this.state.value}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={(e) => this.props.readOnly ? null : this.setState({value: e.target.value})}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Escape') {
                                                        this.setState({value: this.props.value})
                                                        e.target.blur()
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter' && this.props.value == this.state.value) {
                                                        e.target.blur()
                                                    } else if (e.key === 'Enter') {
                                                        this.action.postChange();
                                                        e.target.blur()
                                                    }
                                                }}
                                                onKeyDownCapture={(e) => {
                                                    e.target.style.height = 'inherit';
                                                    e.target.style.height = `${e.target.scrollHeight}px`;
                                                }}
                                                onShow={(e) => {
                                                    e.target.style.height = 'inherit';
                                                    e.target.style.height = `${e.target.scrollHeight}px`;
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <div style={{width: '42px'}}>
                                                <i style={{paddingLeft: 5, cursor: 'pointer', color: 'red'}}
                                                   className="fas fa-times-circle"
                                                   onClick={(e) => {
                                                       e.stopPropagation();
                                                       this.setState({value: this.props.value, edit: false, mouseEnter: false})
                                                   }}/>
                                                <i style={{paddingLeft: 3, cursor: 'pointer', color: 'green'}}
                                                   className="fas fa-check-circle" onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.action.postChange();
                                                    e.target.blur()
                                                }}/>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}

                    </div>



                </React.Fragment>
            )
        } else if(this.props.type == 'TEXTAREA') {
            return (
                <React.Fragment>
                    {this.state.edit == false && (
                        <span
                            ref={(el) => this.refValueComponent = el}
                            style={{cursor: 'pointer', fontSize: this.props.fontSize}}
                            onClick={(e) => this.setState({edit: true})}>
                            {this.props.value ? null : <i style={{cursor: 'pointer', fontSize: this.props.fontSize}}>...</i>}
                            <div style={{margin: '-13px -13px -1px -15px'}}
                                 className="p-editor-content ql-snow">
                                <div style={{background: '#FFFFFF'}} className="ql-editor" dangerouslySetInnerHTML={{ __html: this.props.value }}></div>
                            </div>
                        </span>
                    )}


                    {this.state.edit && (
                        {/*
                        <React.Fragment>
                            <Editor
                                value={this.state.value}
                                onTextChange={(e) => this.setState({value: e.htmlValue})}/>
                            <div>
                                <Button label="abbrechen" icon="pi pi-times" onClick={() => this.setState({value: this.props.value, edit: false})} />
                                <Button style={{marginLeft: 5}} label="speichern" icon="pi pi-check" onClick={() => this.action.postChange()} />
                            </div>
                        </React.Fragment>
                        */}
                    )}
                </React.Fragment>
            )
        } else if(this.props.type == 'DROPDOWN') {

            const listBoxItemTemplate = (option) => {
                return (
                    <div>
                        {option.img && (<img height='12' src={option.img} style={{paddingRight: 10}}/>)}
                        <span>{option.label}</span>
                    </div>
                );
            }

            return (
                <React.Fragment>
                    <span
                        className={styles.inputText}
                        ref={(el) => this.refValueComponent = el}
                        style={{cursor: 'pointer', fontSize: this.props.fontSize, paddingLeft: 2}}
                         onClick={(e) => {
                             e.stopPropagation()
                             this.textOverlayPanel.toggle(e)
                         }}>
                        <span>
                            {this.props.value == null && '...'}
                            {this.props.options.map( item => {
                            if(item?.value != null) {
                                if(JSON.stringify(item.value) === JSON.stringify(this.props.value) || item.value == this.props.value) {
                                    return item.label
                                }
                            } else {
                                if(item === this.props.value) {
                                    return item
                                }
                            }
                        })}</span>
                    </span>

                    <OverlayPanel appendTo='self' ref={(el) => this.textOverlayPanel = el} showCloseIcon id="textOverlayPanel">

                        <ListBox
                            itemTemplate={listBoxItemTemplate}
                            value={this.state.value}
                            options={this.state.options}
                            onChange={(e) => {
                                this.state.value = e.value
                                this.action.postChange()
                                this.setState({ selectedCity: e.value })
                            }}
                            style={{ width: '15rem' }} />
                    </OverlayPanel>
                </React.Fragment>
            )
        } else if(this.props.type == 'LOCALDATE') {
            return (
                <React.Fragment>

                    {this.props.showLabel == true && (
                        <div>
                            <div style={{paddingLeft: '2px', fontSize: '10px'}}>{this.props.label}</div>
                        </div>
                    )}
                    {/*

                    <span
                        ref={(el) => this.refValueComponent = el}
                        style={{cursor: 'pointer', fontSize: this.props.fontSize}}
                         onClick={(e) => this.textOverlayPanel.toggle(e)}>
                        <div className="p-grid">
                            <EECalendar
                                small
                                edit={false}
                                value={this.state.value}/>
                        </div>
                    </span>

                    <OverlayPanel appendTo='self' ref={(el) => this.textOverlayPanel = el} showCloseIcon id="textOverlayPanel">
                        <div className="p-grid">
                            <EECalendar
                                inline
                                edit={true}
                                value={this.state.value}
                                onChange={(value) => {
                                    this.state.valueDate = value
                                    this.action.postLocaldateChange()
                                }}/>
                        </div>
                    </OverlayPanel>
                    */}
                </React.Fragment>
            )
        }

    }
}

export default EEEditable;