import React, {useEffect, useState} from 'react';
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {FileUpload} from "primereact/fileupload";
import styles from "./CreateFormDialog.module.css";
import {AppApi} from "../../AppApi";

import visualImg from "./visual.png"
import voiceImg from "./voice.png"

function Form (props) {

    const [markerType, setMarkerType] = useState("VISUAL");
    const [markerFiles, setMarkerFiles] = useState([]);
    const [markerDescription, setMarkerDescription] = useState();
    const services = {
        postNewMarker: () => {
            AppApi.pub.task.contents.marker.post(props.contentId, props.currentTime, markerDescription, props.markerX, props.markerY, markerType)
                .then(response => {
                    services.upload(response.data.id)
                    props.onClose()
                })
                .catch(console.log)
        },
        chooseUpload: (event) => {
            console.log(event.files)
            setMarkerFiles(Array.from(event.files))
            event.options.clear()
        },
        removeUpload: (attachment) => {
            var files = []
            markerFiles.map( file => {
                if(file.objectURL != attachment.objectURL) {
                    files.push(file)
                }
            })
            setMarkerFiles(files)
        },

        upload: (entityId) => {
            markerFiles.map(file => {
                AppApi.pub.attachment.postUpload(
                    file,
                    entityId).then(response => {
                    props.onClose()
                }).catch(console.log)
            })
        }
    }

    useEffect(() => {
        setMarkerType("VISUAL")
        setMarkerFiles([])
        setMarkerDescription(null)
    }, [props.visible]);

    return (
        <Dialog
            position="top-right"
            style={{marginTop: 60, maxWidth: '650px', height: '610px', width: '640px'}}
            closeOnEscape
            closable
            header="Schreiben Sie hier Ihren Änderungswunsch"
            visible={props.visible}
            blockScroll
            resizable={false}
            draggable={false}
            modal={false}
            onHide={() => props.onClose()}
            footer={() => (
                <div>
                    <div
                        style={{width: 300}}
                        className="button"
                        onClick={() => services.postNewMarker()}>
                        Änderungswunsch hinzufügen <i className="pi pi pi-send"/>
                    </div>
                </div>
            )}>

            <div style={{paddingLeft: 10, paddingRight: 10,paddingTop: 5}}>

                <table>
                    <tbody>
                    <tr>
                        <td><img src={visualImg} width={20}/></td>
                        <td style={{paddingBottom: 5, width: 80}}><span style={markerType == "VISUAL" ? {fontWeight: 600} : {fontWeight: 400, cursor: 'pointer'}} onClick={() => setMarkerType("VISUAL")}>Visuell</span></td>
                        <td><img src={voiceImg} width={20}/></td>
                        <td style={{paddingBottom: 5}}><span style={markerType == "VOICE" ? {fontWeight: 600} : {fontWeight: 400, cursor: 'pointer'}} onClick={() => setMarkerType("VOICE")}>Sprechertext</span></td>
                    </tr>
                    </tbody>
                </table>

                <div style={{paddingTop: 2}}>
                    <InputTextarea
                        rows={3}
                        style={{width: '100%'}}
                        value={markerDescription}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                services.postNewMarker()
                            }
                        }}
                        onChange={ (e) => setMarkerDescription(e.target.value)}
                    />
                    <div>
                        {markerFiles.map( file => (
                            <div>
                                <span className={styles.attachment} onClick={() => services.removeUpload(file)}>{file.name}</span>
                            </div>
                        ))}
                    </div>

                    <div style={{textAlign: 'left', paddingLeft: 2, color: '#01d997'}}>
                        <FileUpload
                            chooseOptions={{icon: 'none', style: {color: 'rgba(0,217,255,0.54)',padding: 0, height: 36, background: 'transparent', border: '0px', textDecoration: 'underline'}}}
                            auto
                            chooseLabel="Anhang hinzufügen..."
                            mode="basic"
                            customUpload={true}
                            uploadHandler={(event) => services.chooseUpload(event)}
                            multiple maxFileSize={200000000} />
                    </div>
                </div>

            </div>

        </Dialog>
    )
}

export default Form;