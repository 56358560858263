import {_DELETE, _DOWNLOAD, _POST, _GET, _PUT} from "./Api"

export const AppApi = {

    prv: {
        task: {
            url: '/api/module/review/tasks',
            get: (filter) => _GET(AppApi.prv.task.url, filter),
            post: () => _POST(AppApi.prv.task.url, null),
            put: (id, field, value) => _PUT(AppApi.prv.task.url + '/'+ id, JSON.stringify({[field]: value})),
            delete: (id) => _DELETE(AppApi.prv.task.url + '/'+ id),
            content: {
                url: '/api/module/review/task/contents',
                postUpload: (file, id) => {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('id', id);
                    return _POST(AppApi.prv.task.content.url + '/upload', formData)
                },
                delete: (id) => _DELETE(AppApi.prv.task.content.url + '/'+ id),
                marker: {
                    url: '/api/module/review/task/content/markers',
                    put: (id, field, value) => _PUT(AppApi.prv.task.content.marker.url + '/'+ id, JSON.stringify({[field]: value})),
                }
            },

        },
        email: {
            url: '/api/module/review/emails',
            postNotification: (reviewId) => _POST(AppApi.prv.email.url + '/notification', reviewId),
        }
    },
    pub: {
        profile: {
            url: '/api/pub/profile',
            post: (body) => _POST(AppApi.pub.profile.url+'/login', body)
        },
        task: {
            url: '/api/pub/module/review/tasks',
            getOneUuid: (uuid) => _GET(AppApi.pub.task.url + '/uuid/' + uuid),
            contents: {
                url: '/api/pub/module/review/task/contents',
                getOne: (id) => _GET(AppApi.pub.task.contents.url + '/' + id),
                download: (id, filename) => _DOWNLOAD(AppApi.pub.task.contents.url + '/download/' + id, filename),
                marker: {
                    url: '/api/pub/module/review/task/content/markers',
                    post: (contentId, playbackRate, description, markerX, markerY, type) => _POST(AppApi.pub.task.contents.marker.url, JSON.stringify({contentId: contentId, playbackRate: playbackRate, description: description, markerX: markerX, markerY: markerY, type: type})),
                    delete: (id) => _DELETE(AppApi.pub.task.contents.marker.url + '/'+ id),
                }
            },
        },
        attachment: {
            url: '/api/pub/module/review/attachments',
            download: (id, filename) => _DOWNLOAD(AppApi.pub.attachment.url + '/download/' + id, filename),
            postUpload: (file, markerId) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('markerId', markerId);
                return _POST(AppApi.pub.attachment.url + '/upload', formData)
            },
        },
    }
}